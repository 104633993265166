import layout from "./reducers/layout";

import app from "./reducers/app";
import session from "./reducers/session";

import companies from "./reducers/companies";
import listings from "./reducers/listings";
import distances from "./reducers/distances";
import talents from "./reducers/talents";
import contacts from "./reducers/contacts";
import application from "./reducers/application";

import email from "./reducers/email";
import chat from "./reducers/chat";

const rootReducer = {
  app,
  layout,
  session,
  companies,
  listings,
  distances,
  talents,
  contacts,
  application,
  email,
  chat,
};

export default rootReducer;
