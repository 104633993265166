import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./api/apollo";

import ReactDOM from "react-dom";
import { Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";

import { store } from "./redux/store";
import { Provider } from "react-redux";

import { ThemeContext } from "./utility/context/ThemeColors";

import themeConfig from "./configs/themeConfig";

import { Toaster } from "react-hot-toast";

import Spinner from "./@core/components/spinner/Fallback-spinner";

import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

import "./@core/scss/core.scss";
import "./@core/components/ripple-button";
import "./@core/assets/fonts/feather/iconfont.css";

import "./assets/scss/style.scss";

import "@styles/base/pages/app-chat.scss";
import "@styles/base/pages/app-email.scss";
import "@styles/base/pages/app-chat-list.scss";
import "@styles/base/pages/app-ecommerce.scss";

import "react-perfect-scrollbar/dist/css/styles.css";
import "react-contexify/dist/ReactContexify.min.css";

import "@styles/react/apps/app-users.scss";
import "@styles/react/apps/app-email.scss";
import "@styles/react/apps/app-ecommerce.scss";

import "@styles/react/pages/page-authentication.scss";

import "@styles/react/libs/editor/editor.scss";
import "@styles/react/libs/flatpickr/flatpickr.scss";
import "@styles/react/libs/noui-slider/noui-slider.scss";
import "@styles/react/libs/context-menu/context-menu.scss";
import "@styles/react/libs/input-number/input-number.scss";
import "@styles/react/libs/react-select/_react-select.scss";
import "@styles/react/libs/tables/react-dataTable-component.scss";
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";

const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <ThemeContext>
          <ApolloProvider client={apolloClient}>
            <LazyApp />
            <Toaster position={themeConfig.layout.toastPosition} toastOptions={{ className: "react-hot-toast" }} />
          </ApolloProvider>
        </ThemeContext>
      </Suspense>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root"),
);
