import { createSlice } from "@reduxjs/toolkit";

const getQueryCondition = (filter) => ({
  subscribed: filter.subscribed,
  nameRegex: filter.name,
});

export const initialState = {
  view: "list",
  sort: {
    label: "Most Recent",
    value: "CREATEDDATE_DESC",
  },
  filter: {
    subscribed: true,
    name: undefined,
  },
  get queryCondition() {
    return getQueryCondition(this.filter);
  },
  calendarSlots: [],
  calendarFormOpen: false,
  sidebarOpen: false,
  count: null,
  data: null,
  page: 0,
  pages: 0,
  pageSize: 1000,
  details: null,
  aclModal: {
    activeListing: null,
    details: {},
  },
  settings: {
    weights: {
      function: 0.25,
      seniority: 0.25,
      industry: 0.25,
      autopilot: 0.25,
    },
    ceilings: {
      function: 0,
      seniority: 0,
      industry: 0,
      autopilot: 0,
    },
    keywords: {
      function: [],
      skill: [],
      seniority: [],
      companyindustry: [],
      companysize: [],
    },
  },
  updatedDate: null,
  prospectSummary: null,
  applicantSummary: null,
  settingsTime: null,
  settingsTab: "configurations",
  settingsDirty: false,
  settingsValid: true,
  settingsLoading: false,
};

export const listings = createSlice({
  name: "listings",
  initialState,
  reducers: {
    updateCalendarSlots: (state, { payload }) => {
      const mappedCalendar = payload.map((x) => {

        const isBooked = x.applicantData?.applicationId;

        const interviewee = x.application?.applicantFields?.fullName; 

        return {
          id: x._id,
          title: `${x.slotData.name} ${interviewee ? `with ${interviewee}` : ""}`,
          start: new Date(x.slotData.start),
          end: new Date(x.slotData.end),
          extendedProps: {
            calendar: isBooked !== null,
          },
          details: x.slotData.link,
          data: x,
        };
      });
      
      state.calendarSlots = mappedCalendar;
    },
    toggleCalendarForm: (state, { payload }) => {
      state.calendarFormOpen = payload;
    },
    toggleSidebar: (state, { payload }) => {
      state.sidebarOpen = payload;
    },
    updateView: (state, { payload }) => {
      state.view = payload;
    },
    updateSort: (state, { payload }) => {
      state.sort = payload;
    },
    updateFilterSubscribed: (state, { payload }) => {
      state.filter.subscribed = payload;
      state.queryCondition = getQueryCondition(state.filter);
      state.page = 0;
    },
    updateFilterName: (state, { payload }) => {
      if (payload === "") {
        state.filter.name = undefined;
      } else {
        state.filter.name = payload;
      }
      state.queryCondition = getQueryCondition(state.filter);
      state.page = 0;
    },
    updateCount: (state, { payload }) => {
      state.count = payload;
      state.pages = Math.ceil(payload / 10) || 1;
    },
    updateData: (state, { payload }) => {
      state.data = payload;
    },
    updatePage: (state, { payload }) => {
      state.page = payload;
    },
    updateDetails: (state, { payload }) => {
      state.details = payload;
      // state.settings = payload.settings;
      // state.updatedDate = payload.updatedDate;
      // state.prospectSummary = payload.prospectSummary;
      // state.applicantSummary = payload.applicantSummary;
    },
    toggleModalListing: (state, { payload }) => {
      state.aclModal.activeListing = payload;
    },
    updateModalListing: (state, { payload }) => {
      state.aclModal.details = payload;
    },
    updateSettingsTime: (state, { payload }) => {
      state.settingsTime = payload;
    },
    updateSettings: (state, { payload }) => {
      const [field, key, value] = payload;
      state.settingsDirty = true;
      state.settings[field][key] = value;

      const totalFloat = Object.values(state.settings.weights).reduce(
        (x, y) => x + y, 0,
      );
      const total = parseFloat(totalFloat.toPrecision(2));

      state.settingsValid = (total === 1);
    },
    updateSettingsTab: (state, { payload }) => {
      state.settingsTab = payload;
    },
    updateSettingsLoading: (state, { payload }) => {
      state.settingsLoading = payload;
    },
    resetSettingsDirty: (state) => {
      state.settingsDirty = false;
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
      state.page = 0;
    },
    resetListings: (state) => {
      state.view = initialState.view;
      state.sort = initialState.sort;
      state.filter = initialState.filter;
      state.count = initialState.count;
      state.data = initialState.data;
      state.page = initialState.page;
      state.pages = initialState.pages;
      state.pageSize = initialState.pageSize;
      state.details = initialState.details;
      state.settings = initialState.settings;
      state.updatedDate = initialState.updatedDate;
      state.prospectSummary = initialState.prospectSummary;
      state.applicantSummary = initialState.applicantSummary;
      state.settingsTime = initialState.settingsTime;
      state.settingsTab = initialState.settingsTab;
      state.settingsDirty = initialState.settingsDirty;
      state.settingsValid = initialState.settingsValid;
    },
  },
});

export const {
  updateCalendarSlots,
  toggleCalendarForm,
  toggleSidebar,
  updateView,
  updateSort,
  updateFilterSubscribed,
  updateFilterName,
  updateCount,
  updateData,
  updatePage,
  updateDetails,
  toggleModalListing,
  updateModalListing,
  updateSettingsTime,
  updateSettings,
  updateSettingsTab,
  updateSettingsLoading,
  resetSettingsDirty,
  resetFilter,
  resetListings,
} = listings.actions;

export default listings.reducer;
