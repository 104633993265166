import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: "application",
  company: {},
  job: {},
  applicantFields: {},
  applicantDetails: {},
};

export const application = createSlice({
  name: "application",
  initialState,
  reducers: {
    updateApplicationCompany: (state, { payload }) => {
      state.company = payload;
    },
    updateApplicationJob: (state, { payload }) => {
      state.job = payload;
    },
    updateApplicationDetails: (state, { payload }) => {
      state.applicantDetails = payload;
    },
    updateApplicantFields: (state, { payload }) => {
      state.applicantFields = payload;
    },
  },
});

export const {
  updateApplicationCompany,
  updateApplicationJob,
  updateApplicantFields,
  updateApplicationDetails,
} = application.actions;

export default application.reducer;
